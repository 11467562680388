#bg{
  width: 100%;
  height: 100vh;
}
#bg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1000;
}
#bg div{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#bg h1{
  text-align: center;
  font-size: 6rem;
  background: -webkit-linear-gradient(rgb(135, 230, 235), rgb(0, 76, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.highlight{
  font-weight: 700;
}
section{
  margin: 1rem 0;
}
#privacy-policy{
  margin: auto;
  width: 991px;
}

@media screen and (max-width: 1024px) {
  #bg h1{
    font-size: 5rem;
  }
}

@media screen and (max-width: 991px) {
  #privacy-policy{
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  #bg h1{
    font-size: 4rem;
  }
}

@media screen and (max-width: 480px) {
  #bg h1{
    font-size: 3rem;
  }
}