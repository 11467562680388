@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&family=Source+Sans+Pro:wght@400;600;700&display=swap');


body {
  margin: 0;
  font-family: 'Noto Serif', serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
